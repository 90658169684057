import 'src/assets/scss/_dataSpinner.scss'
import 'src/assets/scss/_loginComponent.scss'

import { Grid, Button } from '@material-ui/core'
import { App as CngApp, routes, views } from 'cng-web-lib'
import CngRoute from 'src/CngRoute'
import React, { useEffect } from 'react'
import ThemeLibrary from 'src/theme/ThemeLibrary'
import PasswordForm from 'src/views/password/PasswordForm'
import HomePageLayout from 'src/layouts/HomePageLayout'
import { Link as RouterLink,Redirect } from 'react-router-dom'
import HomeView from 'src/views/staticHomeView/HomeView'
import ForgotPasswordForm from 'src/views/password/ForgotPassword'
import UpdatePasswordForm from 'src/views/password/UpdatePasswordForm'
import ResetPasswordForm from 'src/views/password/ResetPassword'
import SecurityQuestionForm from 'src/views/password/SecurityQuestionForm'
import CustomCngLayout from 'src/layouts/CustomCngLayout/index.js'
import CustomFooter from 'src/layouts/CustomCngLayout/CustomFooter/index.js'
import CustomLiveChat from 'src/layouts/CustomCngLayout/CustomLiveChat/index.js'
import ContactUsView from 'src/views/staticHomeView/ContactUsView'

import UPSHomePageLayout from 'src/layouts/HomePageLayout/ups/index.js'
import UPSHomeView from 'src/views/staticHomeView/ups/UPSHomeView'
import UPSContactUsView from 'src/views/staticHomeView/ups/UPSHomeView/ContactUsView'
import { default as UPSProductInfoView } from 'src/views/staticHomeView/ups/UPSHomeView/ProductInfoView'
import { default as UPSTradeResourcesView } from 'src/views/staticHomeView/ups/UPSHomeView/TradeResourcesView'
import { default as UPSFAQView } from 'src/views/staticHomeView/ups/UPSHomeView/FAQView'
import { default as UPSPrivacyPolicyView } from 'src/views/staticHomeView/ups/UPSHomeView/PrivacyPolicyView'
import { default as UPSRegisterView } from 'src/views/staticHomeView/ups/UPSHomeView/RegisterView'
import { default as UPSCheckedIcon } from './components/ups/checkbox/CheckedIcon'
import { default as UPSIcon } from './components/ups/checkbox/Icon'
import { default as UPSIndeterminateIcon } from './components/ups/checkbox/IndeterminateIcon'

import RBIHomePageLayout from './layouts/HomePageLayout/rbi'
import RBIHomeView from 'src/views/staticHomeView/rbi'
import { default as RBIContactUsView } from 'src/views/staticHomeView/rbi/ContactUsView'
import { default as RBIAboutUsView } from 'src/views/staticHomeView/rbi/AboutUsView'

import FCIHomePageLayout from './layouts/HomePageLayout/fci'
import FCIHomeView from 'src/views/staticHomeView/fci'
import { default as FCIContactUsView } from 'src/views/staticHomeView/fci/ContactUsView'
import { default as FCIAboutUsView } from 'src/views/staticHomeView/fci/AboutUsView'

import RAFHomePageLayout from './layouts/HomePageLayout/raf'
import RAFHomeView from 'src/views/staticHomeView/raf'
import { default as RAFProductInfoView } from 'src/views/staticHomeView/raf/ProductInfoView'
import { default as RAFContactUsView } from 'src/views/staticHomeView/raf/ContactUsView'

import classNames from 'classnames'
import KeyboardArrowDownOutlined from '@material-ui/icons/KeyboardArrowDownOutlined'
import ScrollToTop from 'src/common/ScrollToTop'
import LookupsProvider from './contexts/LookupsContext'

const { CngPublicRoute } = routes

const { CngLoginView } = views

function App() {
  const cngRoute = new CngRoute()
  const cngPublicRoute = new CngPublicRoute()
  const cngLoginRoute = cngPublicRoute.getLoginRoute()

	function CustomLoginView() {
    let homePagePath = sessionStorage.getItem("homePagePath");
    if(homePagePath==null || homePagePath==undefined){
      homePagePath = "/"
    }
		return (
		  <CngLoginView
			renderHeaderContent={() => {
        return (
          <Button
          component={RouterLink}
          to={homePagePath}
          className={classNames('btn-go-back')}
        >
          <i
            className={classNames(
              'label-icon',
              'ic-32',
              'ic-bg-white',
              'icon-arrow-back-white'
            )}
          ></i>
          Back to home
        </Button>
        
        )
      }}
			renderFooterContent={() => {""}}
			renderLogo={() => {""}}
		  />
		)
	}

  cngLoginRoute.setComponent(CustomLoginView)

  const defaultConfiguration = [
    {
      exact: true,
      path: '/',
      component: () => <Redirect to='/home' />
    },
    {
      path: '/home',
      layout: HomePageLayout,
      //routes: homePageRoute.getConfigurations(),
      component: HomeView
    },
    {
      path: '/gets-contact-us',
      layout: HomePageLayout,
      //routes: homePageRoute.getConfigurations(),
      component: ContactUsView
    },
    {
      exact: true,
      path: '/forgot',
      component: () => {
        return (
          <ForgotPasswordForm />
        )
      }
    },
    {
      exact: true,
      path: '/forgot/resetpwd',
      component: ResetPasswordForm
    },
    {
      exact: true,
      path: '/security/question',
      component: SecurityQuestionForm
    },
    {
      exact: true,
      path: '/update-password',
      component: () => {
        return (
          <UpdatePasswordForm />
        )
      }
    }
  ]

  const upsConfiguration = [
    {
      path: '/ups',
      layout: UPSHomePageLayout,
      component: UPSHomeView
    },
    {
      path: '/ups-product-info',
      layout: UPSHomePageLayout,
      component: UPSProductInfoView
    },
    {
      path: '/ups-contact-us',
      layout: UPSHomePageLayout,
      component: UPSContactUsView
    },
    {
      path: '/ups-trade-resources',
      layout: UPSHomePageLayout,
      component: UPSTradeResourcesView
    },
    {
      path: '/ups-faq',
      layout: UPSHomePageLayout,
      component: UPSFAQView
    },
    {
      path: '/ups-end-user-web-agreement',
      layout: UPSHomePageLayout,
      component: UPSPrivacyPolicyView
    },
    {
      path: '/ups-register',
      layout: UPSHomePageLayout,
      component: UPSRegisterView
    }
  ]

  const rbiConfiguration = [
    {
      path: '/rbi',
      layout: RBIHomePageLayout,
      component: RBIHomeView
    },
    {
      path: '/rbi-about-us',
      layout: RBIHomePageLayout,
      component: RBIAboutUsView
    },
    {
      path: '/rbi-contact-us',
      layout: RBIHomePageLayout,
      component: RBIContactUsView
    }
  ]

  const fciConfiguration = [
    {
      path: '/fci',
      layout: FCIHomePageLayout,
      component: FCIHomeView
    },
    {
      path: '/fci-about-us',
      layout: FCIHomePageLayout,
      component: FCIAboutUsView
    },
    {
      path: '/fci-contact-us',
      layout: FCIHomePageLayout,
      component: FCIContactUsView
    }
  ]

  const rafConfiguration = [
    {
      path: '/raf',
      layout: RAFHomePageLayout,
      component: RAFHomeView
    },
    {
      path: '/raf-product-info',
      layout: RAFHomePageLayout,
      component: RAFProductInfoView
    },
    {
      path: '/raf-contact-us',
      layout: RAFHomePageLayout,
      component: RAFContactUsView
    }
  ]

  cngPublicRoute.addConfigurations(defaultConfiguration)
  cngPublicRoute.addConfigurations(upsConfiguration)
  cngPublicRoute.addConfigurations(rbiConfiguration)
  cngPublicRoute.addConfigurations(fciConfiguration)
  cngPublicRoute.addConfigurations(rafConfiguration)

  function validateJWT() {
    const token = localStorage.getItem('userToken')

    if (token) {
      const jwtPayload = JSON.parse(window.atob(token.split('.')[1]))

      if (jwtPayload.exp < Date.now() / 1000) {
        localStorage.clear();
      }
    }
  }

  useEffect(() => {
    validateJWT()
  }, [])

  window.addEventListener('beforeunload', function(event) {
    validateJWT()
    //localStorage.clear();
  }, false);

  function getThemeLibrary(whiteLabelServiceSettings) {
    const whiteLabelThemeLibrarySettings = whiteLabelServiceSettings.find(
      (whiteLabelServiceSettings) => {
        return whiteLabelServiceSettings.serviceName === "custom-layout"
      }
    )

    let themeNames = undefined
    let themeConfigurations = undefined
    if (whiteLabelThemeLibrarySettings) {
      if(whiteLabelThemeLibrarySettings.custom.themeNames!=undefined && whiteLabelThemeLibrarySettings.custom.themeConfigurations!=undefined){
        themeNames = JSON.parse(whiteLabelThemeLibrarySettings.custom.themeNames)
        themeConfigurations = JSON.parse(whiteLabelThemeLibrarySettings.custom.themeConfigurations)
  
        themeConfigurations.forEach((themeConfiguration) => {
          if (themeConfiguration?.props) {
            themeConfiguration.props = {
              ...themeConfiguration.props,
              MuiAutocomplete: {
                popupIcon: <KeyboardArrowDownOutlined />
              },
              MuiCheckbox: {
                checkedIcon: <UPSCheckedIcon />,
                icon: <UPSIcon />,
                indeterminateIcon: <UPSIndeterminateIcon />
              },
              MuiSelect: {
                IconComponent: KeyboardArrowDownOutlined
              }
            }
          }
        })
      }
    }
    return new ThemeLibrary(themeNames, themeConfigurations)
  }

return (
  <CngApp
    extraCngRouteConfigurations={[
      ...cngRoute.getConfigurations(),
      {
        exact: true,
        path: '/change-password',
        component: () =>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PasswordForm />
            </Grid>
          </Grid>,
        requireAuthorization: false
      }
    ]}
    publicRoute={cngPublicRoute}
    getThemeLibrary={getThemeLibrary}
    onClickChangePassword={(history) => { history.push('/change-password') }}
    renderCngLayout={(children) => {
      return (
        <CustomCngLayout>
          <ScrollToTop>
            <LookupsProvider>{children}</LookupsProvider>
          </ScrollToTop>
          {/* <ZohoLiveChat isChatOpen='show' /> */}
          <CustomLiveChat />
          <CustomFooter />
        </CustomCngLayout>
      )
    }}
  />
)
}

export default App
