const AceOcnManifestApiUrls = {
  GET: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/manifest/get`,
  POST: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/manifest/create`,
  PUT: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/manifest/update`,
  DELETE: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/manifest/delete`,
  EXPORT: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/manifest/download`,
  ATTACH: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/manifest/attach`,
  DETACH: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/manifest/detach`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_ACE_OCEAN_URL}/ace-ocean/manifest/submitAll`,
};

export default AceOcnManifestApiUrls
