const FdapnApiUrls = {
  GET: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/pn/get`,
  POST: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/pn/create`,
  PUT: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/pn/update`,
  DELETE: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/pn/delete`,
  EXPORT: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/pn/download`,
  SUBMIT: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/pn/submit`,
  SUBMIT_ALL: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/pn/submitAll`,
  CLONE: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/pn/clone`,
  UPLOAD: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/pn/upload`,
  PRINT: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/pn/printReport`,
  GET_AUDIT_LOGS: `${process.env.REACT_APP_CNG_ADMIN_ORIGIN_URL}/cng-admin/audit-header/get`,

  SEARCH_GET: `${process.env.REACT_APP_USER_FDA_PN_URL}/fda-pn/pn-search/get`,
};

export default FdapnApiUrls
