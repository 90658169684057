const SbciInvoiceApiUrls = {
  GET: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/get`,
  POST: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/create`,
  PUT: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/update`,
  DELETE: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/delete`,
  EXPORT: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice-search/download`,
  UPLOAD: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/upload`,
  GET_AUDIT_LOGS: `${process.env.REACT_APP_CNG_ADMIN_ORIGIN_URL}/cng-admin/audit-header/get`,
  SEARCH: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice-search/get`,
  SEARCH_INVOICE_MANIFEST: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice-manifest/get`,
  CLONE: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/clone`,
  DOWNLOAD:  `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/downloadAttachment`,
  UNLOCK: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/unlock`,
  ATTACH_SEARCH: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice-attach-search/get`,
  CREATE_ACE_SHIPMENT: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/convertSbciAceHwy`,
  PRINT_USMCA_CUSMA: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/print_USMCA_CUSMA`,
  PRINT_BOL: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/print_BOL`,
  PRINT_PL: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/print_PL`,
  PRINT_TSCA: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/print_TSCA`,
  PRINT_LACEYACT: `${process.env.REACT_APP_USER_SBCI_URL}/sbci/sbci-invoice/printLaceyAct`,
};

export default SbciInvoiceApiUrls
