const NbciUsInvoiceApiUrls = {
  GET: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/get`,
  POST: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/create`,
  PUT: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/update`,
  DELETE: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/delete`,
  EXPORT: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/download`,
  UPLOAD: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/upload`,
  GET_AUDIT_LOGS: `${process.env.REACT_APP_CNG_ADMIN_ORIGIN_URL}/cng-admin/audit-header/get`,
  SUBMIT: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/submit`,
  CLONE: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/clone`,
  RESPONSE_GET: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/response/get`,
  PRINT_BOL: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printBol`,
  PRINT_USMCACUSMA: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printUsmcaCusma`,
  PRINT_LACEYACT: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printLaceyAct`,
  PRINT_PL: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printPackingList`,
  PRINT_TSCA: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printTsca`,
  PRINT_DOT: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printDot`,
  PRINT_FCC: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printFCC`,
  PRINT_FDA: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printFDA`,
  PRINT_EPA_1: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printEPA1`,
  PRINT_EPA_21: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printEPA21`,
  PRINT_FDA_BTA: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printFDABTA`,
  PRINT_USAPHIS: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printUSAPHIS`,
  PRINT_DOT_ALL: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printDotAll`,
  PRINT_FCC_ALL: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printFCCAll`,
  PRINT_FDA_ALL: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printFDAAll`,
  PRINT_EPA_1_ALL: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printEPA1All`,
  PRINT_EPA_21_ALL: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printEPA21All`,
  PRINT_CI_FORM: `${process.env.REACT_APP_USER_NBCI_URL}/nbci/us-invoice/printInvceFormsAll`,

};

export default NbciUsInvoiceApiUrls
